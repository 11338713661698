




























































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

@Component
export default class ServiceSurveyShow extends Vue {
  survey = {
    aggregatedAnswers: [],
    textAnswers: []
  }

  chart = {
    data: [],
    options: {
      title: 'Resumo da pesquisa',
      axes: {
        left: {
          mapsTo: 'value'
        },
        bottom: {
          mapsTo: 'group',
          scaleType: 'labels'
        }
      },
      height: '600px'
    }
  }

  created () {
    axios.get(`/service/survey/${this.$route.params.id}`)
      .then(response => {
        this.survey = camelCaseKeys(response.data.data, { deep: true })
        this.chart.data = this.survey.aggregatedAnswers
      })
  }

  buildChart () {
    console.log('asd')
  }
}

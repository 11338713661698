





















































































import { Component, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import FlashMessageMixin from '@/mixins/flashMessageMixin'

@Component
export default class ServiceSurveyAnswer extends Mixins(FlashMessageMixin) {
  survey: ServiceSurvey = {
    subject: '',
    survey: '',
    options: []
  }

  selectedOptions = []
  selectedOption = ''

  optionsOthers: ServiceSurveyOption | null = null
  optionOthersText = ''

  surveyType = ''
  done = true

  created () {
    this.fetchSurvey()
  }

  fetchSurvey () {
    axios.get(`/service/${this.$route.params.serviceId}/survey/${this.$route.params.surveyId}`)
      .then(response => {
        this.survey = response.data.data.survey
        this.optionsOthers = this.survey.options.find(option => option.type === 'text') || null
        this.survey.options = this.survey.options.filter(option => option.type !== 'text')

        this.surveyType = this.survey.options[0].type
      })
  }

  archiveMessage () {
    this.done = false
    axios.delete(`/service/${this.$route.params.serviceId}/survey/${this.$route.params.surveyId}`)
      .then(() => {
        const flashMessage = {
          message: 'A pesquisa foi arquivada',
          isSuccess: true
        }
        this.$router.push({ name: 'ServicesShow', params: { id: this.$route.params.serviceId } }, () => {
          this.setFlashMessage(flashMessage)
        })
      })
      .finally(() => { this.done = true })
  }

  submit () {
    this.done = false
    if (this.selectedOptions.length) {
      const submitForm: any = []
      this.selectedOptions.forEach((option) => {
        if (option === this.optionsOthers?.id) {
          submitForm.push({ optionId: option, text: this.optionOthersText })
        } else {
          submitForm.push({ optionId: option })
        }
      })
      axios.post(`/service/${this.$route.params.serviceId}/survey/${this.$route.params.surveyId}/answer`, snakeCaseKeys(submitForm, { deep: true }))
        .then(() => {
          const flashMessage = {
            message: 'Obrigado por responder a pesquisa',
            isSuccess: true
          }
          this.$router.push({ name: 'ServicesShow', params: { id: this.$route.params.serviceId } }, () => {
            this.setFlashMessage(flashMessage)
          })
        })
        .finally(() => { this.done = true })
    } else if (this.selectedOption) {
      const submitForm = {
        optionId: '',
        text: ''
      }
      submitForm.optionId = this.selectedOption

      if (this.selectedOption === this.optionsOthers?.id) {
        submitForm.text = this.optionOthersText
      }

      axios.post(`/service/${this.$route.params.serviceId}/survey/${this.$route.params.surveyId}/answer`, [snakeCaseKeys(submitForm)])
        .then(() => {
          const flashMessage = {
            message: 'Obrigado por responder a pesquisa',
            isSuccess: true
          }
          this.$router.push({ name: 'ServicesShow', params: { id: this.$route.params.serviceId } }, () => {
            this.setFlashMessage(flashMessage)
          })
        })
        .finally(() => { this.done = true })
    }
  }
}
